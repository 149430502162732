<template>
  <div id="user_center">
    <div class="order">
      <div class="img_bg"></div>
      <!-- 未登录 -->
      <p class="user_name">{{dataInfo ? dataInfo.username : language.l_23 }}</p>
      <div class="user_head">
        <div class="head_img">
          <img v-if="dataInfo" @click="toUserInfo" :src="dataInfo.avatar || avatar" alt="">
          <img v-else @click="toLogin" :src="avatar"/>
        </div>
      </div>
      <div class="header-abouts">
        <div @click="toOrderList(0)" class="header-about">
          <img src="@img/mine/dingdan.png" />
          <!-- 全部订单 -->
          <span>{{language.l_24}}</span>
        </div>
        <div @click="toOrderList(1)" class="header-about">
          <img src="@img/mine/daifu.png" />
          <!-- 待付款 -->
          <span>{{language.l_25}}</span>
        </div>
        <div @click="toOrderList(3)" class="header-about">
          <img src="@img/mine/daishou.png" />
          <!-- 待收货 -->
          <span>{{language.l_26}}</span>
        </div>
        <div @click="toService" class="header-about">
          <img src="@img/mine/shouhou.png" />
          <!-- 退货/售后 -->
          <span>{{language.l_27}}</span>
        </div>
      </div>
    </div>
    <div class="list_admin">
      <van-cell @click="toAddress" is-link>
        <template>
          <svg class="font-icon" aria-hidden="true">
            <use xlink:href="#icon_adr"></use>
          </svg>
          <!-- 地址管理 -->
          <span>{{language.l_28}}</span>
        </template>
      </van-cell>
      <van-cell>
        <template>
          <svg aria-hidden="true" class="icon font-icon">
            <use xlink:href="#iconyue"></use>
          </svg>
          <!-- 余额 -->
          <span>{{language.l_29}}</span>
        </template>
        <template #right-icon>
          <span>${{balance}}</span>
        </template>
      </van-cell>
      <van-cell @click="toRecharge" is-link>
        <template>
          <svg aria-hidden="true" class="icon font-icon">
            <use xlink:href="#iconchongzhi"></use>
          </svg>
          <!-- 充值余额 -->
          <span>{{language.l_30}}</span>
        </template>
      </van-cell>
      <van-cell is-link @click="toConsume">
        <template>
          <svg aria-hidden="true" class="icon font-icon">
            <use xlink:href="#iconchongzhijilu"></use>
          </svg>
          <!-- 消费记录 -->
          <span>{{language.l_31}}</span>
        </template>
      </van-cell>
    </div>
  </div>
</template>

<script>
import {getUserInfo} from '@api';

export default {
  data() {
    return {
      fromPage: null, // 页面来源 - 用于登录后路由重定向
      dataInfo: null,
      isLogin: this.$store.state.token,
      balance: '0.00',
      avatar: require('@img/home/user_avatar.png'),
      language: this.$store.getters.language
    };
  },
  created () {
    const {isLogin} = this
    if(isLogin) {
      getUserInfo().then(res => {
        const {user} = res.data
        this.dataInfo = user
        this.balance = user.balance
      })
    }
  },
  methods: {
    toLogin(){
      this.$router.push({name: 'Login'})
    },
    toUserInfo() {
      this.$router.push({name: 'UserInfo'})
    },
    toOrderList(active) {  //跳转订单列表
      if(this.isLogin) return this.$router.push({name: 'OrderList', query: {active}})
      this.toLogin();
    },
    toService() {  //跳转售后列表
      if(this.isLogin) return this.$router.push({name: 'Service'})
      this.toLogin();
    },
    toAddress() {  //跳转地址列表
      if(this.isLogin) return this.$router.push({name: 'MineAddress'})
      this.toLogin();
    },
    toRecharge() {  //充值余额
      if(this.isLogin) return this.$router.push({name: 'MineRecharge'})
      this.toLogin();
    },
    toConsume() {  //消费账单
      if(this.isLogin) return this.$router.push({name: 'MineConsume'})
      this.toLogin();
    }
  }
};
</script>
<style lang='less' scoped>
#user_center {
  .order {
    width: 100%;
    height: 330px;
    background: #fff;
    border-radius: 0 0 30px 30px;
    .img_bg {
      width: 100%;
      height: 179px;
      background: url(~@img/mine/bg.png);
      border-radius: 0 0 30px 30px;
    }
    .user_name {
      color: #fff;
      margin-top: -90px;
      padding-bottom: 20px;
      text-align: center;
      font-size: 14px;
    }
    .user_head {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: #fff;
      margin: auto;
      // margin-top: -50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .head_img {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        img {
          width: 90px;
          height: 90px;
          border-radius: 50%;
        }
      }
    }
    .header-abouts  {
      position: relative;
      display: flex;
      justify-content: space-around;
      height: 45px;
      background-color: #fff;
      border-radius: 15px;
      margin: 10px auto 0;
      padding: 12px 0 8px;
      .header-about  {
        display: flex;
        flex-direction: column;
        align-items: center;
        img  {
          width: 25px;
          height: 25px;
          margin-bottom: 4px;
        }
        span  {
          font-size: 12px;
        }
      }
    }

  }
  /deep/ .van-cell {
    background: transparent;
  }
  /deep/ .van-cell::after {
    border-color: rgba(0,0,0,0.1);
  }
  .font-icon {
    width: 24px;
    height: 24px;
    padding-right: 15px;
  }
  .van-cell__value {
    display: flex;
  }
  .list_admin {
    /deep/ .van-cell {
      padding: 17px 16px !important;
    }
  }
}
</style>
